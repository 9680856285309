app-guest-info {
  .card-img-top {
    max-width: 21vw;
  }

  .custom-card-img-top {
    position: relative;
  }

  .custom-card-img-top::before {
    display: block;
    padding-top: 100%;
    content: '';
  }

  .custom-card-img-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 420px;
    max-width: 420px;
    text-align: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.03);

    img {
      max-height: 420px;
      max-width: 420px;
      width: 100%;
      vertical-align: middle;
      align-self: center;
      border-top-left-radius: $card-border-radius;
      border-top-right-radius: $card-border-radius;
      -webkit-border-top-left-radius: $card-border-radius;
      -webkit-border-top-right-radius: $card-border-radius;
    }
  }
}
